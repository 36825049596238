<template>
  <div class="merchandiseList flex flex-wrap">
    <div
      v-for="(item, index) in merchandiseList"
      :key="index"
      :class="item.isState ? 'merchandiseList-li1' : 'merchandiseList-li'"
      @mouseleave="handleMouseOver(index)"
      @mouseenter="handuleMouseenter(index)"
      @click="lookover(item)"
    >
      <div
        class="merchandlist flex flex-direction items-center flex-center"
        :class="
          item.isState ? 'merchandiseList-li1-div' : 'merchandiseList-li-div'
        "
      >
        <img src="" alt="" class="merchandiseList-li-div-img" />
        <div
          :class="
            item.isState
              ? 'merchandiseList-li1-div-text'
              : 'merchandiseList-li-div-text'
          "
        >
          武器
        </div>
      </div>
      <div
        class="merchandiseListbutton"
        item.isState
        :class="
          item.isState
            ? 'merchandiseList-li1-hover'
            : 'merchandiseList-li-hover'
        "
      >
        点击购买
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      merchandiseList: [
        {
          isState: false,
        },
        {
          isState: false,
        },
        {
          isState: false,
        },
        {
          isState: false,
        },
      ],
    };
  },
  methods: {
    handleMouseOver(index) {
      this.merchandiseList[index].isState = false;
    },
    handuleMouseenter(index) {
      this.merchandiseList[index].isState = true;
    },
    lookover(item) {
      this.$emit("lookover", item);
    },
  },
};
</script>
<style lang="scss">
.merchandiseList {
  .merchandlist {
    width: 238px;
    height: 212px;
    margin-bottom: 12px;
  }

  .merchandiseListbutton {
    width: 240px;
    height: 72px;
    line-height: 72px;
    text-align: center;
  }

  &-li {
    margin-bottom: 33px;
    margin-right: 16px;

    &-div {
      background: url("https://cdn.bubbleplan.cn/static/img/border.png")
        no-repeat;
      background-size: 100% 100%;

      &-img {
        width: 122px;
        height: 122px;
      }

      &-text {
        color: #ffffff;
        font-size: 20px;
        margin-top: 3px;
      }
    }

    &-hover {
      background: url("https://cdn.bubbleplan.cn/static/img/button.png")
        no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      font-size: 22px;
    }
  }

  &-li1 {
    margin-bottom: 33px;
    margin-right: 16px;
    margin-top: -10px;

    &-div {
      background: url("https://cdn.bubbleplan.cn/static/img/border_button.png")
        no-repeat;
      background-size: 100% 100%;

      &-img {
        width: 122px;
        height: 122px;
      }

      &-text {
        color: #daff00;
        font-size: 22px;
        margin-top: 3px;
        font-weight: 700;
      }
    }

    &-hover {
      background: url("https://cdn.bubbleplan.cn/static/img/button_hover.png")
        no-repeat;
      background-size: 100% 100%;
      color: #daff00;
      font-size: 22px;
      font-weight: 700;
    }
  }
}
</style>
<template>
  <div class="knapsack">
    <div class="knapsack-back flex">
      <div
        style="
          width: 50%;
          border-right: 1px solid rgba(255, 255, 255, 0.4);
          height: 26px;
        "
      >
        <i class="el-icon-arrow-left"></i>
      </div>
      <div
        style="
          width: 50%;
          text-align: center;
          line-height: 26px;
          color: rgb(255, 255, 255);
          font-size: 22px;
        "
      >
        仓库
      </div>
    </div>
    <div class="knapsack-put flex">
      <div class="knapsack-put-left">
        <div
          v-for="(item, index) in type"
          :key="index"
          :class="
            typeLindex == index
              ? 'knapsack-put-left-select'
              : 'knapsack-put-left-hover'
          "
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div style="margin-left: 46px; width: 1050px">
        <merchandiseList @lookover="lookover"></merchandiseList>
      </div>
    </div>

    <el-dialog title="" :visible.sync="singleShow" width="30%">
      <div class="singleClass">
        <div class="flex">
          <div>
            <img
              src=""
              alt=""
              style="width: 184px; height: 218px; border-radius: 25px"
            />
          </div>
          <div
            class="flex flex-direction flex-evenly"
            style="margin-left: 33px; color: #ffffff; font-size: 22px"
          >
            <div>道具名称：急救针</div>
            <div>道具介绍：急救针</div>
            <div>持有数量：1000</div>
            <div>当前最低售价：11金币/10个</div>
          </div>
        </div>
        <div class="singleClass-introduce">
          道具功能介绍道具功能介绍道具功能介绍道具功能介绍道具功能介绍
        </div>
        <div class="singleClass-button flex flex-between">
          <el-button
            type="primary"
            round
            style="background: #daff00; color: #000000"
            >购买</el-button
          >
          <el-button
            type="primary"
            round
            style="background: #841d89; color: #ffffff"
            >售出</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import merchandiseList from "./components/merchandiseList.vue";
export default {
  components: {
    merchandiseList,
  },
  data() {
    return {
      type: [
        {
          name: "背包",
        },
        {
          name: "上价中",
        },
        {
          name: "交易历史",
        },
      ],
      typeLindex: 0,
      singleShow: false,
    };
  },
  methods: {
    lookover(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="scss">
.knapsack {
  min-height: calc(100vh - 94px);
  background: #9a13a5;
  padding-top: 40px;
  padding-left: 308px;

  &-back {
    width: 249px;
    height: 54px;
    flex-shrink: 0;
    border-radius: 276px;
    background: #7e158b;
    padding: 14px 39px;

    .el-icon-arrow-left {
      line-height: 26px;
      color: #fff;
      font-size: 22px;
    }
  }

  &-put {
    margin-top: 36px;

    &-left {
      &-select {
        width: 249px;
        height: 68px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #daff00;
        line-height: 68px;
        color: rgb(16, 6, 30);
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        // letter-spacing: 1.2px;
        margin-bottom: 44px;
      }

      &-hover {
        width: 177px;
        height: 54px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #7e158b;
        color: rgb(255, 255, 255);
        font-size: 22px;
        line-height: 54px;
        text-align: center;
        margin-bottom: 44px;
      }
    }
  }

  .singleClass {
    // width: 648px;
    // height: 614px;
    &-introduce {
      width: 100%;
      padding: 28px 30px;
      color: #93a3af;
      font-size: 22px;
      border-radius: 14px;
      background: #021521;
      margin-top: 16px;
    }

    &-button {
      margin-top: 40px;
      padding: 0 72px;
    }
  }

  .el-dialog {
    border-radius: 20px;
    background: #13212f;
  }

  .el-dialog__close el-icon el-icon-close {
    color: #64849a;
    font-size: 20px;
  }

  .el-button--primary {
    width: 197px;
    height: 64px;
    border-radius: 50px;
    font-size: 22px;
    border: none;
  }
}
</style>